import { Avatar } from 'app/shared/components/generic-ui/Avatar/Avatar';
import { Table } from 'app/shared/components/generic-ui/Table/Table';
import {
  CellAlignment,
  CellSize,
  TableHeaderType,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { getUserInitials } from 'app/shared/utils';
import { useCallback } from 'react';
import { INotificationSent } from 'types';
import { useStyles } from './NotificationsSentModal.styles';

interface INotificationsSentModalProps {
  notifications: INotificationSent[];
  notificationName: string;
}

export const NotificationsSentModal = ({
  notifications,
  notificationName,
}: INotificationsSentModalProps) => {
  const classes = useStyles();

  const defaultColumns = [
    {
      id: 'name',
      label: 'Name',
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
    },
    {
      id: 'userEmail',
      label: 'Email',
      alignment: CellAlignment.RIGHT,
      size: CellSize.SMALL,
    },
  ];
  const renderCell = useCallback(
    (rowData: any, cellId: string) => {
      if (cellId === 'name') {
        const cellContent = [rowData.firstName, rowData.lastName];
        return (
          <div className={classes.name}>
            <Avatar
              content={
                cellContent[0] &&
                cellContent[1] &&
                getUserInitials(cellContent[0], cellContent[1])
              }
              color="highlightBlue"
              size={32}
            />
            <div className={classes.text}>
              {cellContent[0] &&
                cellContent[1] &&
                `${cellContent[0]} ${cellContent[1]}`}
            </div>
          </div>
        );
      } else {
        return <div>{rowData[cellId] || '-'}</div>;
      }
    },
    [classes.name, classes.text],
  );

  return (
    <div>
      <div className={classes.header}>
        <CustomTypography variant="subtitle1" color="accentDark">
          {notificationName.toUpperCase()}
        </CustomTypography>
      </div>

      <div className={classes.internalContent}>
        <Table
          columns={defaultColumns}
          tableData={notifications || []}
          withCheckbox={false}
          renderCell={renderCell}
          headerType={TableHeaderType.NO_HEADER}
          withBorders={false}
          rowId={'userId'}
          isLoading={false}
          isScrollable={false}
          overflow="visible"
          withHover={false}
        />
      </div>
    </div>
  );
};
