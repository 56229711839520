import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useStyles } from './BatchesFilterBlock.styles';
import { DatePicker } from 'app/shared/components/generic-ui/DatePicker/DatePicker';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { useBatchHistoryList, useScreen } from 'app/shared/hooks';
import { themeColors, themeLayout } from 'app/shared/theme/theme';
import {
  IHistoryRecord,
  IMetaData,
  NotificationEntityType,
} from 'types/general';
import { EntitiesDropdown } from '../../EntitiesDropdown';
import { firebaseAnalytics } from 'app/shared/analytics';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from 'types';
import { format, startOfDay } from 'date-fns';
import { useAuth } from 'app/shared/providers';
import { EventStatusDropdown } from '../../EntitiesDropdown/EventStatusDropdown';

interface IFilterBlockProps {
  withStatusesOnly: boolean;
  isUserAssignedEntities: boolean;
  loadingHandler: (value: boolean) => void;
  batchesHistoryHandler: (items: IHistoryRecord[], metadata: IMetaData) => void;
  refresh: boolean;
}

const skipOnePage = 1;

export const BatchesFilterBlock = ({
  withStatusesOnly,
  isUserAssignedEntities,
  batchesHistoryHandler,
  loadingHandler,
  refresh,
}: IFilterBlockProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    organizationId,
    page,
    perPage,
  }: { organizationId: string; page: string; perPage: string } = useParams();
  const { userId } = useAuth();
  const ticketId = new URLSearchParams(location.search).get('ticketId');
  const history = useHistory();

  const dateFilterFormat = useCallback((date: Date | undefined | null) => {
    return date
      ? format(
          startOfDay(new Date(date.toISOString())),
          "yyyy-MM-dd'T'HH:mm:ssxxx",
        )
      : '';
  }, []);

  const [selectedPlants, setSelectedPlants] = useState<
    { id: string; label: string }[]
  >(JSON.parse(localStorage.getItem('batchesPlants') || '[]'));
  const [selectedCustomers, setSelectedCustomers] = useState<
    { id: string; label: string }[]
  >(JSON.parse(localStorage.getItem('batchesCustomers') || '[]'));
  const [selectedMixDesigns, setSelectedMixDesigns] = useState<
    { id: string; label: string }[]
  >(JSON.parse(localStorage.getItem('batchesMixDesigns') || '[]'));
  const [selectedVehicles, setSelectedVehicles] = useState<
    { id: string; label: string }[]
  >(JSON.parse(localStorage.getItem('batchesVehicles') || '[]'));
  const [selectedDrivers, setSelectedDrivers] = useState<
    { id: string; label: string }[]
  >(JSON.parse(localStorage.getItem('batchesDrivers') || '[]'));
  const [selectedProjects, setSelectedProjects] = useState<
    { id: string; label: string }[]
  >(JSON.parse(localStorage.getItem('batchesProjects') || '[]'));
  const [selectedPourEvents, setSelectedPourEvents] = useState<
    { id: string; label: string; additionalId?: string }[]
  >(JSON.parse(localStorage.getItem('batchesPourEvents') || '[]'));
  const [selectedEventStatusCodes, setSelectedEventStatusCodes] = useState<
    { id: string; label: string; additionalId?: string }[]
  >(JSON.parse(localStorage.getItem('batchesEventStatusCodes') || '[]'));

  const [startDate, setStartDate] = useState<Date | undefined | null>(
    !localStorage.getItem('batchesFrom') ||
      localStorage.getItem('batchesFrom') === 'null'
      ? localStorage.getItem('batchesFrom') === 'null'
        ? null
        : null
      : new Date(localStorage.getItem('batchesFrom') || ''),
  );
  const [endDate, setEndDate] = useState<Date | undefined | null>(
    !localStorage.getItem('batchesTo') ||
      localStorage.getItem('batchesTo') === 'null'
      ? null
      : new Date(localStorage.getItem('batchesTo') || ''),
  );

  const { isMobile } = useScreen(themeLayout.breakpointBig);

  const clearAllButtonHandler = useCallback(() => {
    setSelectedPlants([]);
    setSelectedCustomers([]);
    setSelectedDrivers([]);
    setSelectedMixDesigns([]);
    setSelectedPourEvents([]);
    setSelectedProjects([]);
    setSelectedVehicles([]);
    setSelectedEventStatusCodes([]);
  }, []);

  const clearButton = useMemo(() => {
    return (
      <Button onClick={clearAllButtonHandler} height={42} variant="clearAll">
        <div className={classes.clearAll}>
          <CustomTypography variant="buttonTextSmall" bold color={'mbsBlue'}>
            {t(translations.filterBlock.clearAll)}
          </CustomTypography>

          <div className={classes.close}>
            <CloseIcon
              height={9}
              stroke={themeColors.mbsBlue}
              strokeWidth={3}
            />
          </div>
        </div>
      </Button>
    );
  }, [classes.clearAll, classes.close, clearAllButtonHandler, t]);

  const changeHandler = useCallback((from?: Date | null, to?: Date | null) => {
    setStartDate(from);
    setEndDate(to);
  }, []);

  const removePlantHandler = useCallback(
    (
      entity: { id: string; label: string; additionalId?: string },
      entityType: NotificationEntityType,
    ) => {
      switch (entityType) {
        case NotificationEntityType.PLANT: {
          setSelectedPlants(prevState =>
            prevState.filter(item => entity.id !== item.id),
          );
          if (!isUserAssignedEntities) {
            setSelectedCustomers([]);
            setSelectedMixDesigns([]);
            setSelectedDrivers([]);
            setSelectedVehicles([]);
            setSelectedProjects([]);
            setSelectedPourEvents([]);
          }

          break;
        }
        case NotificationEntityType.CUSTOMER: {
          setSelectedCustomers(prevState =>
            prevState.filter(item => entity.id !== item.id),
          );

          if (!isUserAssignedEntities) {
            setSelectedMixDesigns([]);
            setSelectedDrivers([]);
            setSelectedVehicles([]);
            setSelectedProjects([]);
            setSelectedPourEvents([]);
          }

          break;
        }
        case NotificationEntityType.MIX_DESIGN: {
          setSelectedMixDesigns(prevState =>
            prevState.filter(item => entity.id !== item.id),
          );

          if (!isUserAssignedEntities) {
            setSelectedDrivers([]);
            setSelectedVehicles([]);
            setSelectedProjects([]);
            setSelectedPourEvents([]);
          }

          break;
        }
        case NotificationEntityType.VEHICLE: {
          setSelectedVehicles(prevState =>
            prevState.filter(item => entity.id !== item.id),
          );

          if (!isUserAssignedEntities) {
            setSelectedDrivers([]);
            setSelectedProjects([]);
            setSelectedPourEvents([]);
          }

          break;
        }
        case NotificationEntityType.DRIVER: {
          setSelectedDrivers(prevState =>
            prevState.filter(item => entity.id !== item.id),
          );

          if (!isUserAssignedEntities) {
            setSelectedProjects([]);
            setSelectedPourEvents([]);
          }

          break;
        }
        case NotificationEntityType.PROJECT: {
          setSelectedProjects(prevState =>
            prevState.filter(item => entity.id !== item.id),
          );

          if (!isUserAssignedEntities) {
            setSelectedPourEvents([]);
          }

          break;
        }
        case NotificationEntityType.POUR_EVENT: {
          setSelectedPourEvents(prevState =>
            prevState.filter(item => entity.additionalId !== item.additionalId),
          );
          break;
        }
        default:
          break;
      }
    },
    [isUserAssignedEntities],
  );

  const { fetchBatchesHistory, isFetchingBatchesHistory } = useBatchHistoryList(
    {
      organizationId: organizationId,
    },
  );

  const selectedEntitiesIds = useMemo(() => {
    return {
      plantExternalIds: selectedPlants.map(el => el.id),
      mixDesignExternalIds: selectedMixDesigns.map(el => el.id),
      vehicleExternalIds: selectedVehicles.map(el => el.id),
      driverExternalIds: selectedDrivers.map(el => el.id),
      customerExternalIds: selectedCustomers.map(el => el.id),
      pourEventGIDs: selectedPourEvents.map(el => el.additionalId || ''),
      projectExternalIds: selectedProjects.map(el => el.id),
      eventStatusCodes: selectedEventStatusCodes.map(el => el.id),
    };
  }, [
    selectedCustomers,
    selectedDrivers,
    selectedMixDesigns,
    selectedPlants,
    selectedPourEvents,
    selectedProjects,
    selectedVehicles,
    selectedEventStatusCodes,
  ]);

  const batchHistoryParams = useMemo(() => {
    const params = {
      skip: `${(+page - skipOnePage) * +perPage}`,
      take: perPage,
      startDateTime: startDate ? dateFilterFormat(startDate) : '',
      endDateTime: endDate
        ? dateFilterFormat(endDate)
        : !endDate && startDate
        ? dateFilterFormat(startDate)
        : '',
      lastElementDateTimeUtc: '',

      ticketsWithStatusesOnly: withStatusesOnly,
      ...selectedEntitiesIds,
    };

    return isUserAssignedEntities
      ? {
          ...params,
          userId,
          crossmatching: false,
        }
      : { ...params, crossmatching: true };
  }, [
    dateFilterFormat,
    endDate,
    isUserAssignedEntities,
    page,
    perPage,
    selectedEntitiesIds,
    startDate,
    userId,
    withStatusesOnly,
  ]);

  const applyFilterHandler = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.Batches,
      'Apply filters for Batches',
      JSON.stringify(batchHistoryParams),
    );

    localStorage.setItem('batchesFrom', startDate?.toISOString() || '');
    localStorage.setItem('batchesTo', endDate?.toISOString() || '');
    localStorage.setItem('batchesPlants', JSON.stringify(selectedPlants || []));
    localStorage.setItem('batchesCustomers', JSON.stringify(selectedCustomers));
    localStorage.setItem(
      'batchesMixDesigns',
      JSON.stringify(selectedMixDesigns),
    );
    localStorage.setItem('batchesVehicles', JSON.stringify(selectedVehicles));
    localStorage.setItem('batchesDrivers', JSON.stringify(selectedDrivers));
    localStorage.setItem('batchesProjects', JSON.stringify(selectedProjects));
    localStorage.setItem(
      'batchesPourEvents',
      JSON.stringify(selectedPourEvents),
    );
    localStorage.setItem(
      'batchesEventStatusCodes',
      JSON.stringify(selectedEventStatusCodes),
    );

    localStorage.setItem('ticketId', '');

    history.push(
      Routes.Batches.replace(
        ':organizationId/:page/:perPage',
        `${organizationId}/1/20`,
      ),
    );

    fetchBatchesHistory(batchHistoryParams).then(res =>
      batchesHistoryHandler(res.data, res.metadata),
    );
  }, [
    batchHistoryParams,
    batchesHistoryHandler,
    endDate,
    fetchBatchesHistory,
    history,
    organizationId,
    selectedCustomers,
    selectedDrivers,
    selectedMixDesigns,
    selectedPlants,
    selectedPourEvents,
    selectedProjects,
    selectedVehicles,
    startDate,
    selectedEventStatusCodes,
  ]);

  useEffect(() => {
    if (!ticketId) {
      fetchBatchesHistory(batchHistoryParams).then(res =>
        batchesHistoryHandler(res.data, res.metadata),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    perPage,
    withStatusesOnly,
    isUserAssignedEntities,
    ticketId,
    refresh,
  ]);

  useEffect(() => {
    if (ticketId || isUserAssignedEntities) {
      clearAllButtonHandler();
    }
  }, [clearAllButtonHandler, ticketId, isUserAssignedEntities]);

  useEffect(() => {
    loadingHandler(isFetchingBatchesHistory);
  }, [isFetchingBatchesHistory, loadingHandler]);

  const dropdowns = useMemo(() => {
    return [
      {
        entityType: NotificationEntityType.PLANT,
        onChangeSelectedEntities: (
          values: string[],
          items?: { id: string; label: string }[],
        ) => {
          setSelectedPlants(items || []);
        },
        selectedValues: selectedPlants.map(el => el.id),
        selectedItems: selectedPlants,
        label: t(translations.filterBlock.plant),
      },
      {
        entityType: NotificationEntityType.CUSTOMER,
        onChangeSelectedEntities: (
          values: string[],
          items?: { id: string; label: string }[],
        ) => {
          setSelectedCustomers(items || []);
        },
        selectedValues: selectedCustomers.map(el => el.id),
        selectedItems: selectedCustomers,
        label: t(translations.filterBlock.customer),
      },
      {
        entityType: NotificationEntityType.MIX_DESIGN,
        onChangeSelectedEntities: (
          values: string[],
          items?: { id: string; label: string }[],
        ) => {
          setSelectedMixDesigns(items || []);
        },
        selectedValues: selectedMixDesigns.map(el => el.id),
        selectedItems: selectedMixDesigns,
        label: t(translations.filterBlock.mixDesign),
      },
      {
        entityType: NotificationEntityType.VEHICLE,
        onChangeSelectedEntities: (
          values: string[],
          items?: { id: string; label: string }[],
        ) => {
          setSelectedVehicles(items || []);
        },
        selectedValues: selectedVehicles.map(el => el.id),
        selectedItems: selectedVehicles,
        label: t(translations.filterBlock.vehicle),
      },
      {
        entityType: NotificationEntityType.DRIVER,
        onChangeSelectedEntities: (
          values: string[],
          items?: { id: string; label: string }[],
        ) => {
          setSelectedDrivers(items || []);
        },
        selectedValues: selectedDrivers.map(el => el.id),
        selectedItems: selectedDrivers,
        label: t(translations.filterBlock.driver),
      },
      {
        entityType: NotificationEntityType.PROJECT,
        onChangeSelectedEntities: (
          values: string[],
          items?: { id: string; label: string }[],
        ) => {
          setSelectedProjects(items || []);
        },
        selectedValues: selectedProjects.map(el => el.id),
        selectedItems: selectedProjects,
        label: t(translations.filterBlock.project),
      },
      {
        entityType: NotificationEntityType.POUR_EVENT,
        onChangeSelectedEntities: (
          values: string[],
          items?: { id: string; label: string; additionalId?: string }[],
        ) => {
          setSelectedPourEvents(items || []);
        },
        selectedValues: selectedPourEvents.map(el => el.additionalId || el.id),
        selectedItems: selectedPourEvents,
        label: t(translations.filterBlock.pourEvent),
      },
    ];
  }, [
    selectedCustomers,
    selectedDrivers,
    selectedMixDesigns,
    selectedPlants,
    selectedPourEvents,
    selectedProjects,
    selectedVehicles,
    t,
  ]);

  const hasSelectedItems = useMemo(() => {
    return (
      !!selectedCustomers.length ||
      !!selectedDrivers.length ||
      !!selectedMixDesigns.length ||
      !!selectedPlants.length ||
      !!selectedPourEvents.length ||
      !!selectedProjects.length ||
      !!selectedVehicles.length ||
      !!selectedEventStatusCodes.length
    );
  }, [
    selectedCustomers.length,
    selectedDrivers.length,
    selectedMixDesigns.length,
    selectedPlants.length,
    selectedPourEvents.length,
    selectedProjects.length,
    selectedVehicles.length,
    selectedEventStatusCodes.length,
  ]);

  const renderSelectedItems = useMemo(() => {
    return dropdowns.map(el => {
      return el.selectedItems.map(item => (
        <div key={item.id} className={classes.selectedItem}>
          <CustomTypography
            variant="caption1"
            color="greyscale1"
            style={{ whiteSpace: 'nowrap' }}
          >
            {`${item.id}/${item.label}`}
          </CustomTypography>

          <div
            className={classes.closeButton}
            onClick={() => removePlantHandler(item, el.entityType)}
          >
            <CloseIcon height={15} />
          </div>
        </div>
      ));
    });
  }, [
    classes.closeButton,
    classes.selectedItem,
    dropdowns,
    removePlantHandler,
  ]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.dropdownsContainer}>
          {dropdowns.map(el => (
            <EntitiesDropdown
              {...el}
              key={el.entityType}
              selectedEntitiesIds={selectedEntitiesIds}
              isCrossmatching={!isUserAssignedEntities}
            />
          ))}
          <EventStatusDropdown
            label={t(translations.filterBlock.historyStatus)}
            onChangeSelectedEntities={(_, items) =>
              setSelectedEventStatusCodes(items || [])
            }
            selectedItems={selectedEventStatusCodes}
            selectedValues={selectedEventStatusCodes.map(el => el.label)}
          />
        </div>
      </div>

      <div className={classes.dateContainer}>
        <div className={classes.datePickerContainer}>
          <DatePicker
            changeDateHandler={changeHandler}
            startDate={startDate ? new Date(startDate) : undefined}
            endDate={endDate ? new Date(endDate) : undefined}
          />
        </div>

        {!isMobile && (
          <div className={classes.applyButton}>
            <Button
              onClick={applyFilterHandler}
              height={42}
              variant={'secondaryLight'}
            >
              <CustomTypography
                variant="buttonTextSmall"
                bold
                color={'mbsBlue'}
              >
                {t(translations.datePicker.apply)}
              </CustomTypography>
            </Button>
          </div>
        )}
      </div>

      <div className={classes.selectedItems}>
        {renderSelectedItems}
        {selectedEventStatusCodes.map(item => (
          <div key={item.id} className={classes.selectedItem}>
            <CustomTypography
              variant="caption1"
              color="greyscale1"
              style={{ whiteSpace: 'nowrap' }}
            >
              {`${item.label}`}
            </CustomTypography>

            <div
              className={classes.closeButton}
              onClick={() =>
                setSelectedEventStatusCodes(curr =>
                  curr.filter(i => i.id !== item.id),
                )
              }
            >
              <CloseIcon height={15} />
            </div>
          </div>
        ))}
        {hasSelectedItems && !isMobile ? <>{clearButton}</> : null}
      </div>

      {isMobile && (
        <div className={classes.buttonContainer}>
          {hasSelectedItems ? <>{clearButton}</> : null}

          <Button
            onClick={applyFilterHandler}
            height={42}
            variant={'secondaryLight'}
          >
            <CustomTypography variant="buttonTextSmall" bold color={'mbsBlue'}>
              {t(translations.datePicker.apply)}
            </CustomTypography>
          </Button>
        </div>
      )}
    </div>
  );
};
