import React, { useState } from 'react';
import { AppHeader } from './../AppHeader/AppHeader';
import { SideNavigation } from './../SideNavigation/SideNavigation';
import { Footer } from './../Footer/Footer';
import { useStyles } from './Layout.styles';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store/types';
import { useScreen } from 'app/shared/hooks';
import { themeLayout } from 'app/shared/theme';
import { useLocation } from 'react-router-dom';
import { Routes } from 'types';

interface ILayout {
  children: JSX.Element | JSX.Element[];
  withSideNavigation?: boolean;
  isLoggedIn?: boolean;
}

export const Layout = ({
  children,
  withSideNavigation = true,
  isLoggedIn,
}: ILayout) => {
  const [sideNavWidth, setSideNavWidth] = useState(
    `${themeLayout.sideNavWidth}px`,
  );
  const classes = useStyles();
  const isSideNavOpen = useSelector(
    (state: RootState) => state.layout.isSideNavOpen,
  );
  const location = useLocation();
  const { isMobile } = useScreen(themeLayout.breakpointBig);

  return (
    <div
      className={
        isMobile && location.pathname === Routes.Profile
          ? classes.mainWrapperMobileProfile
          : classes.mainWrapper
      }
      data-testid="mainWrapper"
    >
      {isLoggedIn !== false && <AppHeader />}
      {withSideNavigation && (
        <SideNavigation setSideNavWidth={setSideNavWidth} />
      )}
      <div
        className={classes.pageWrapper}
        data-testid="pageWrapper"
        style={{
          marginLeft: isSideNavOpen && !isMobile ? sideNavWidth : '52px',
        }}
      >
        {children}
      </div>
      <Footer />
    </div>
  );
};
