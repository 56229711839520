import { Table } from 'app/shared/components/generic-ui/Table/Table';
import {
  mapDropdowns,
  useBatchHistoryTableConfig,
  useProbeDataFormatter,
} from 'app/shared/hooks';
import { useCallback, useState } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import {
  IBatchEvent,
  IHistoryRecord,
  ILatestMeasurement,
  ILatestMeasurementValue,
  Routes,
} from 'types';
import { useParams } from 'react-router';
import { useStyles } from './BatchList.styles';
import { ReactComponent as InfoIcon } from 'assets/info.svg';
import { BatchDetailsModal, ModalType } from 'app/pages/pourEventDetails';
import { format } from 'date-fns';
import { PopoverItem, PopoverType } from './PopoverItem';
import { themeColors } from 'app/shared/theme/theme';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { firebaseAnalytics } from 'app/shared/analytics';
import {
  ActionButton,
  ActionButtonStyle,
  IAction,
} from 'app/shared/components/generic-ui/ActionButton/ActionButton';
import { CircularProgress } from 'app/shared/components/generic-ui/CircularProgress';

const oneElement = 1;

interface IBatchListProps {
  statuses: Record<string, string[]>;
  batchList?: IHistoryRecord[];
  isLoading?: boolean;
  rowsTotal: number;
  baseURL?: string;
  refreshHandler: () => void;
}

const formatMask = 'MMM d, y - hh:mm aaa';

export const BatchList = ({
  statuses,
  batchList,
  isLoading,
  rowsTotal,
  baseURL,
  refreshHandler,
}: IBatchListProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { organizationId }: { organizationId: string } = useParams();
  const { renderProbeData, formatProbeValue } = useProbeDataFormatter();

  const [crn, setCrn] = useState(
    new URLSearchParams(location.search).get('crn') || '',
  );
  const [isBatchDetailsOpen, setIsBatchDetailsOpen] = useState(!!crn);
  const [rowData, setRowData] = useState<IHistoryRecord | null>(null);
  const [modalType, setModalType] = useState<ModalType | undefined>();

  const onRefresh = () => {
    refreshHandler();
  };

  const { defaultColumns } = useBatchHistoryTableConfig(onRefresh, statuses);
  const [columns, setColumns] = useState(defaultColumns);

  const onClickRowHandler = (value: IBatchEvent, modalType?: ModalType) => {
    setCrn(value.crn);
    setModalType(modalType);
    setIsBatchDetailsOpen(true);
  };

  const onChangeTableColumns = useCallback(
    (id: string, label: string) => {
      localStorage.setItem(id, label);
      const splittedId = id.split('status');
      const columnId = splittedId.length === 2 ? splittedId[1] : 0;

      //@ts-ignore
      setColumns(prevState => {
        const currentTableColumns = prevState.map(item => {
          const dropDownValues =
            item.id === `property${columnId}`
              ? mapDropdowns(statuses[label])
              : item.dropDownValues;
          return {
            ...item,
            label:
              item.id === id
                ? label
                : item.id === `property${columnId}`
                ? dropDownValues?.find(value => value.value === item.label)
                    ?.value ||
                  dropDownValues?.[0].value ||
                  '-'
                : item.label,
            dropDownValues: dropDownValues,
          };
        });
        return currentTableColumns;
      });
    },
    [statuses],
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [popoverType, setPopoverType] = useState<PopoverType>(PopoverType.INFO);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      rowData: any,
      type: PopoverType,
    ) => {
      setRowData(rowData);
      setAnchorEl(event.currentTarget);
      setPopoverType(type);
    },
    [],
  );

  const handlePopoverClose = useCallback(() => {
    setRowData(null);
    setAnchorEl(null);
  }, []);

  const actions: () => IAction[] = useCallback(() => {
    const allActions = [
      {
        label: t(translations.pourEventPage.addManualData),
        onClick: (row: IBatchEvent) => {
          firebaseAnalytics.logPressEvent(
            Routes.SinglePourEvent,
            'Open Add Manual Data Modal',
          );

          onClickRowHandler(row, ModalType.MANUAL_DATA);
        },
      },
      {
        label: t(translations.pourEventPage.addComprStrength),
        onClick: (row: IBatchEvent) => {
          firebaseAnalytics.logPressEvent(
            Routes.SinglePourEvent,
            'Open Add Compressive Strength Modal',
          );

          onClickRowHandler(row, ModalType.COMPRESSIVE_STRENGTH);
        },
      },
    ];

    return allActions;
  }, [t]);

  const renderCell = useCallback(
    (
      rowData: any,
      cellId: string,
      index: number,
      label: string,
      isHovered?: boolean,
    ) => {
      if (cellId === 'historyType') {
        return (
          <div
            style={{ zIndex: 9999 }}
            onMouseEnter={e => {
              isHovered && handlePopoverOpen(e, rowData, PopoverType.INFO);
            }}
            onMouseLeave={() => {
              isHovered && handlePopoverClose();
            }}
          >
            <InfoIcon stroke={themeColors.mbsBlue} />
          </div>
        );
      } else if (cellId === 'historyRecordName') {
        return (
          <div>
            <CustomTypography
              variant="bodySmall"
              color={rowData[cellId] === 'Voided' ? 'systemRed' : 'accentDark'}
            >
              {rowData[cellId]}
            </CustomTypography>
          </div>
        );
      } else if (cellId === 'totalOrdered') {
        return (
          <div>
            {`${formatProbeValue(rowData.cumulativeVolume)}/${renderProbeData(
              rowData[cellId],
            )}`}
          </div>
        );
      } else if (cellId === 'dispatchDateTimeUtc') {
        return (
          <div>{format(new Date(rowData.dispatchDateTimeUtc), formatMask)}</div>
        );
      } else if (cellId === 'mixId') {
        return (
          <div
            onMouseEnter={e => {
              isHovered && handlePopoverOpen(e, rowData, PopoverType.MIX_ID);
            }}
            onMouseLeave={() => {
              isHovered && handlePopoverClose();
            }}
          >
            <div>{`${rowData.externalProductId} / `}</div>
            <div
              className={classes.productName}
            >{`${rowData.externalProductName}`}</div>
          </div>
        );
      } else if (cellId.includes('property')) {
        const propIndx = cellId.charAt(cellId.length - oneElement);
        const currentStatus = columns.find(el => el.id === `status${propIndx}`);

        const latestMeasurement = rowData?.latestMeasurements?.find(
          (el: ILatestMeasurement) =>
            el.eventStatusCode === currentStatus?.label,
        );

        const currentValue = latestMeasurement?.probeValues?.find(
          (value: ILatestMeasurementValue) => value.propertyName === label,
        )?.propertyValue;

        return (
          <div className={classes.wrapper}>
            <div
              className={
                latestMeasurement?.isManualMeasurement
                  ? classes.valueContainerDark
                  : ''
              }
            >
              {renderProbeData(currentValue)}
            </div>
          </div>
        );
      } else if (cellId === 'actions') {
        return (
          <div>
            <ActionButton
              style={ActionButtonStyle.LIGHT}
              options={actions()}
              rowData={rowData}
            />
          </div>
        );
      } else {
        return (
          <div>
            {typeof rowData[cellId] === 'string'
              ? rowData[cellId]
              : renderProbeData(rowData[cellId])}
          </div>
        );
      }
    },
    [
      actions,
      classes.productName,
      classes.valueContainerDark,
      classes.wrapper,
      columns,
      formatProbeValue,
      handlePopoverClose,
      handlePopoverOpen,
      renderProbeData,
    ],
  );

  return (
    <div className={classes.container}>
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress withLabel />
        </div>
      ) : null}

      {crn && (
        <BatchDetailsModal
          isOpen={isBatchDetailsOpen}
          crn={crn}
          closeHandler={() => {
            setIsBatchDetailsOpen(false);
            setCrn('');
            onRefresh();
          }}
          modalType={modalType}
        />
      )}

      <PopoverItem
        rowData={rowData}
        open={open}
        anchor={anchorEl}
        type={popoverType}
      />

      <Table
        columns={columns}
        tableData={batchList}
        withCheckbox={false}
        rowsTotal={rowsTotal}
        renderCellWithIndexOfRow={renderCell}
        withColoredRows={true}
        onClickRow={onClickRowHandler}
        withPagination={true}
        baseURL={
          baseURL ||
          Routes.Batches.replace(':organizationId', `${organizationId}`)
        }
        withPerPageCount={true}
        isLoading={false}
        rowId={'crn'}
        emptyTableText={t(translations.pourEventPage.noHistory)}
        headRowsCount={2}
        onChangeHeadCell={onChangeTableColumns}
        withHover={true}
      />
    </div>
  );
};
