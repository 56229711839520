import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import React, { useCallback, useState } from 'react';
import { INote, Routes } from 'types';
import { useStyles } from './Note.styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { ReactComponent as AcknowledgedIconWithNotes } from 'assets/file-text.svg';
import { firebaseAnalytics } from 'app/shared/analytics';

interface INoteProps {
  note: INote;
  index: number;
}

const formatMask = 'hh:mm aa';
const offset = 1;

export const Note = ({ note, index }: INoteProps) => {
  const classes = useStyles();
  const [isExpand, setIsExpand] = useState(false);
  const { t } = useTranslation();

  const handleComponentClick = useCallback(() => {
    firebaseAnalytics.logPressEvent(
      Routes.SinglePourEvent,
      'Batch event card acknowledgement spoiler',
    );

    setIsExpand(prevState => !prevState);
  }, []);

  return (
    <>
      <div className={classes.headerClickable} onClick={handleComponentClick}>
        <div className={classes.text}>
          <div className={classes.title}>
            <AcknowledgedIconWithNotes />

            <div className={classes.paddingContainer}>
              <CustomTypography variant="bodySmall" bold color="mbsBlue">
                {`${t(translations.batchEventCard.note)} #${index + offset}`}
              </CustomTypography>
            </div>
          </div>

          <CustomTypography variant="caption2" bold color="greyscale2">
            {`${t(translations.batchEventCard.by)} ${note.firstName || '-'} ${
              note.lastName || ''
            } ${t(translations.batchEventCard.at)} ${format(
              new Date(note.createdOnUtc),
              formatMask,
            )}`}
          </CustomTypography>
        </div>

        <div className={classes.arrowContainer}>
          {isExpand ? (
            <ExpandLessIcon className={classes.expandIcon} />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} />
          )}
        </div>
      </div>
      {isExpand && (
        <div className={classes.noteContainer}>
          <CustomTypography variant="bodyRegular" color="accentDark">
            {note.noteText}
          </CustomTypography>
        </div>
      )}
    </>
  );
};
