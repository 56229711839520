import {
  NotFoundPage,
  PourEventDetails,
  ProfilePage,
  PourEventCardsPage,
  ProjectInfoPage,
  NotificationEntityPage,
  TeammatesPage,
  OrganizationsPage,
  AddOrganizationPage,
  OrganizationDetailsWrapper,
  ContactUsPage,
  BatchesPage,
  ProjectRoleAssignments,
  Report,
  NotificationSettingPage,
} from 'app/pages';

export enum Routes {
  Home = '/',
  MyProfile = '/my-profile/:organizationId',
  Profile = '/profile/:organizationId',
  EditProfile = '/profile/edit/:organizationId',
  Batches = '/batches/:organizationId/:page/:perPage',
  NotificationSettings = '/notifications/:organizationId/:tab/:page/:perPage/:alternativePage/:alternativePerPage',
  Projects = '/projects/:organizationId',
  NotificationEntityPage = '/notification-entity/:entityType/:organizationId',
  NotificationEntityPourEventsPage = '/notification-entity/pour-events/:entityType/:organizationId/:notificationEntityId/:externalId/:name/:page/:perPage',
  Teammates = '/teammates/:organizationId/:page/:perPage/:orderType',
  ProjectInfo = '/project/info/:organizationId/:id',
  ProjectRoles = '/project/roles/:organizationId/:id',
  ProjectEvents = '/project/events/:organizationId/:id/:page/:perPage',
  SinglePourEvent = '/project/events/:organizationId/:id/:pourEventId/:externalProductId/:name/:page/:perPage',
  Organizations = '/organizations',
  AddOrganization = '/organizations/add',
  OrganizationDetails = '/organizations/details/:organizationId/:tab/:page/:perPage',
  EditOrganization = '/organizations/edit/:organizationId',
  MyOrganization = '/my-organization/:organizationId/:tab/:page/:perPage',
  Reports = '/reports/:organizationId/:tab',
  ContactUs = '/contact-us/:organizationId',
  Login = '/login',
  Not_Found = '',
}

export interface IRoute {
  path: string;
  component: React.FC;
  redirect?: string;
}

const commonRoutes: IRoute[] = [
  {
    path: Routes.MyProfile,
    component: ProfilePage,
  },
  {
    path: Routes.Profile,
    component: ProfilePage,
  },
  {
    path: Routes.EditProfile,
    component: ProfilePage,
  },
  {
    path: Routes.NotificationSettings,
    component: NotificationSettingPage,
  },
  {
    path: Routes.Teammates,
    component: TeammatesPage,
  },
  {
    path: Routes.NotificationEntityPage,
    component: NotificationEntityPage,
  },
  {
    path: Routes.Batches,
    component: BatchesPage,
  },
  {
    path: Routes.SinglePourEvent,
    component: PourEventDetails,
  },
  {
    path: Routes.NotificationEntityPourEventsPage,
    component: PourEventCardsPage,
  },
  {
    path: Routes.ProjectInfo,
    component: ProjectInfoPage,
  },
  {
    path: Routes.ProjectRoles,
    component: ProjectRoleAssignments,
  },
  {
    path: Routes.MyOrganization,
    component: OrganizationDetailsWrapper,
  },
  {
    path: Routes.ContactUs,
    component: ContactUsPage,
  },
  {
    path: Routes.Not_Found,
    component: NotFoundPage,
  },
];

export const adminRoutes: IRoute[] = [
  {
    path: Routes.Home,
    component: NotificationEntityPage,
    redirect: Routes.NotificationEntityPage,
  },
  {
    path: Routes.EditOrganization,
    component: AddOrganizationPage,
  },
  {
    path: Routes.OrganizationDetails,
    component: OrganizationDetailsWrapper,
  },
  ...commonRoutes,
];

export const superAdminRoutes: IRoute[] = [
  {
    path: Routes.Home,
    component: OrganizationsPage,
    redirect: Routes.Organizations,
  },
  {
    path: Routes.OrganizationDetails,
    component: OrganizationDetailsWrapper,
  },
  {
    path: Routes.EditOrganization,
    component: AddOrganizationPage,
  },
  {
    path: Routes.Organizations,
    component: OrganizationsPage,
  },
  {
    path: Routes.AddOrganization,
    component: AddOrganizationPage,
  },
  {
    path: Routes.Reports,
    component: Report,
  },
  ...commonRoutes,
];

export const memberRoutes: IRoute[] = [
  {
    path: Routes.Home,
    component: NotificationEntityPage,
    redirect: Routes.NotificationEntityPage,
  },
  {
    path: Routes.NotificationEntityPage,
    component: NotificationEntityPage,
  },
  {
    path: Routes.MyProfile,
    component: ProfilePage,
  },
  {
    path: Routes.EditProfile,
    component: ProfilePage,
  },
  {
    path: Routes.Batches,
    component: BatchesPage,
  },
  {
    path: Routes.NotificationSettings,
    component: NotificationSettingPage,
  },
  {
    path: Routes.SinglePourEvent,
    component: PourEventDetails,
  },
  {
    path: Routes.NotificationEntityPourEventsPage,
    component: PourEventCardsPage,
  },
  {
    path: Routes.ProjectInfo,
    component: ProjectInfoPage,
  },
  {
    path: Routes.ContactUs,
    component: ContactUsPage,
  },
  {
    path: Routes.Not_Found,
    component: NotFoundPage,
  },
];
