import React, { useMemo, useState } from 'react';
import { useStyles } from './NotificationSettings.styles';

import {
  INotification,
  IUpdateNotificationStatusAllGroup,
  IUpdateNotificationProperty,
  ITeamAssignments,
  IEnableNotificationAllGroup,
  IDeleteUserNotificationAssignmentsPayload,
  IGetUserNotificationAssignmentsPayload,
} from 'types';

import {
  getNotificationsDefaultTab,
  sortInAlphabeticalOrder,
} from 'app/shared/utils';
import { NotificationsTabs } from './NotificationsTabs';
import { NotificationsSetup } from './NotificationsSetup';
import { TeammatesSearch } from './TeammatesSearch';
import { TeammatesList } from './TeammatesList';
import { PaginationType } from '../Table/Table.types';
import { useParams } from 'react-router-dom';

interface INotificationSettings {
  header?: string;
  isLoading?: boolean;
  isFetching: boolean;
  teammatesList?: ITeamAssignments[];
  teammatesListTotalCount?: number;
  searchList?: ITeamAssignments[];
  notifications: INotification[] | undefined;
  isFetchingTeammatesList: boolean;
  isFetchingSearch: boolean;
  baseUrl: string;
  searchTermHandler?: (value: string) => void;
  changeNotificationStatus: (notification: {
    notificationTypeId?: string;
    active: boolean;
    projectId?: string;
  }) => void;
  changeNotificationsStatusAll: (
    group: IUpdateNotificationStatusAllGroup,
  ) => void;
  changeUsersNotificationStatus: (
    users: {
      userId: string;
      notificationTypeId: string;
      isAssigned: boolean;
    }[],
  ) => void;
  changeNotificationProperty: (
    notification: IUpdateNotificationProperty,
  ) => void;
  sortHandler?: (id: string) => void;
  searchParams?: URLSearchParams;
  paginationType?: PaginationType;
  enableNotificationsAll?: (group: IEnableNotificationAllGroup) => void;
  enableNotification?: (notification: {
    notificationTypeId?: string;
    enable: boolean;
  }) => void;
  removeAllOtherUserNotificationAssignments?: (
    payload: IDeleteUserNotificationAssignmentsPayload,
  ) => void;

  getUserNotificationAssignmentsCall?: (
    payload: IGetUserNotificationAssignmentsPayload,
    notification: INotification | undefined,
    teamAssignment: ITeamAssignments,
  ) => void;
}

const emptyArrayLength = 0;
const lower = -1;
const bigger = 1;
const equal = 0;
const firstSymbolPosition = 0;
const secondSymbolPosition = 1;
const notificationsCount = 6;

export const NotificationSettings = ({
  isFetching,
  notifications,
  changeNotificationStatus,
  changeNotificationsStatusAll,
  changeUsersNotificationStatus,
  changeNotificationProperty,
  enableNotificationsAll,
  enableNotification,
  teammatesList,
  isFetchingTeammatesList,
  searchList,
  isFetchingSearch,
  teammatesListTotalCount,
  searchTermHandler,
  sortHandler,
  baseUrl,
  searchParams,
  paginationType,
  removeAllOtherUserNotificationAssignments,
  getUserNotificationAssignmentsCall,
}: INotificationSettings) => {
  const classes = useStyles();
  const { tab }: { tab: string } = useParams();
  const notificationTab = new URLSearchParams(location.search).get('tab');
  const eventStatusCode = new URLSearchParams(location.search).get('code');

  const [selectedTeammates, setSelectedTeammates] = useState<
    ITeamAssignments[]
  >([]);

  const notificationsCategories = useMemo(() => {
    let categories: string[] = [];
    notifications?.forEach(notification => {
      if (
        !categories.includes(notification.description) &&
        notification.description
      ) {
        categories = [...categories, notification.description];
      }
    });

    const finalCategories = categories.map(category => {
      return category
        .split(' ')
        .map(
          el =>
            el.charAt(firstSymbolPosition).toUpperCase() +
            el.slice(secondSymbolPosition, el.length),
        )
        .join(' ');
    });

    return finalCategories.sort((firstCategory, secondCategory) =>
      firstCategory !== secondCategory
        ? firstCategory < secondCategory
          ? lower
          : bigger
        : equal,
    );
  }, [notifications]);

  const sortedNotifications = useMemo(() => {
    return notifications
      ? sortInAlphabeticalOrder('asc', notifications, 'notificationTypeId')
      : [];
  }, [notifications]);

  const currentTab = useMemo(() => {
    const targetNotificationDescription = sortedNotifications.find(
      notification => notification.notificationCode === eventStatusCode,
    )?.description;

    return eventStatusCode
      ? notificationsCategories.find(
          category => category.toLowerCase() === targetNotificationDescription,
        )
      : undefined;
  }, [eventStatusCode, notificationsCategories, sortedNotifications]);

  const currentNotifications = useMemo(() => {
    const notificationsToRender = Array(notificationsCount).fill(undefined);

    const filteredNotifications = sortedNotifications?.filter(
      notification =>
        notification.description ===
        (currentTab
          ? currentTab.toLowerCase()
          : !notificationTab
          ? getNotificationsDefaultTab(tab).toLowerCase()
          : notificationTab?.toLowerCase()),
    );

    notificationsToRender.splice(
      emptyArrayLength,
      filteredNotifications.length,
      ...filteredNotifications,
    );

    return notificationsToRender;
  }, [sortedNotifications, currentTab, notificationTab, tab]);

  const description = useMemo(() => {
    return currentTab
      ? currentTab.toLowerCase()
      : !notificationTab
      ? getNotificationsDefaultTab(tab).toLowerCase()
      : notificationTab?.toLowerCase();
  }, [notificationTab, currentTab, tab]);

  const tableColumnsIds = useMemo(() => {
    return currentNotifications.map(el => {
      return el?.notificationCode;
    });
  }, [currentNotifications]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.searchContainer}>
        <TeammatesSearch
          teammates={searchList}
          isLoading={isFetchingSearch}
          searchTermHandler={searchTermHandler}
          selectedTeammatesHandler={setSelectedTeammates}
          searchParams={searchParams}
        />
        <div className={classes.tabContainer}>
          <NotificationsTabs
            categories={notificationsCategories}
            currentTab={currentTab}
          />

          <NotificationsSetup
            description={description}
            changeNotificationsStatusAll={changeNotificationsStatusAll}
            enableNotificationsAll={enableNotificationsAll}
            enableNotification={enableNotification}
            isLoading={isFetching}
            notifications={currentNotifications}
            changeNotificationProperty={changeNotificationProperty}
            changeNotificationStatus={changeNotificationStatus}
          />
        </div>
      </div>
      <div>
        <TeammatesList
          baseUrl={baseUrl}
          teammates={teammatesList}
          selectedTeammates={selectedTeammates}
          isLoading={isFetchingTeammatesList}
          totalCount={teammatesListTotalCount}
          columnIds={tableColumnsIds}
          changeUsersNotificationStatus={changeUsersNotificationStatus}
          sortHandler={sortHandler}
          notifications={currentNotifications}
          searchParams={searchParams}
          paginationType={paginationType}
          removeAllOtherUserNotificationAssignments={
            removeAllOtherUserNotificationAssignments
          }
          getUserNotificationAssignmentsCall={
            getUserNotificationAssignmentsCall
          }
        />
      </div>
    </div>
  );
};
