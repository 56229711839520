import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useStyles } from './PourEventHistorySection.styles';
import {
  IBatchEvent,
  Routes,
  IHistoryRecord,
  HistoryType,
  ILatestMeasurement,
  ILatestMeasurementValue,
} from 'types';
import { Table } from 'app/shared/components/generic-ui/Table/Table';
import { ReactComponent as AlertIcon } from 'assets/alert-circle.svg';
import { useParams } from 'react-router-dom';
import {
  useHistoryTableConfig,
  useProbeDataFormatter,
  useBatchApi,
  mapDropdowns,
} from 'app/shared/hooks';
import { SectionSpoiler } from 'app/shared/components/generic-ui/SectionSpoiler';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useState, useCallback } from 'react';
import { CircularProgress } from 'app/shared/components/generic-ui/CircularProgress';
import { ExactBanner } from '../ExactBanner';
import { themeColors } from 'app/shared/theme/theme';
import {
  ActionButton,
  ActionButtonStyle,
  IAction,
} from 'app/shared/components/generic-ui/ActionButton/ActionButton';
import { firebaseAnalytics } from 'app/shared/analytics';

export enum ModalType {
  MANUAL_DATA,
  COMPRESSIVE_STRENGTH,
}
interface IPourEventHistorySectionProps {
  pourEventHistory?: IHistoryRecord[];
  rowsTotal: number;
  onClick?: (crn: string, modalType?: ModalType) => void;
  isLoading: boolean;
  name: string;
  probeStatuses: Record<string, string[]>;
  onRefresh: () => void;
}

const defaultRows = 0;
const divideByTwo = 2;
const noReminderOfDivision = 0;
const oneElement = 1;

export const PourEventHistorySection = ({
  pourEventHistory,
  rowsTotal,
  onClick,
  isLoading,
  name,
  probeStatuses,
  onRefresh,
}: IPourEventHistorySectionProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const status = new URLSearchParams(location.search).get('status');
  const searchParams = new URLSearchParams({
    ['status']: status || '',
  });

  const {
    id,
    pourEventId,
    organizationId,
    externalProductId,
  }: {
    id: string;
    pourEventId: string;
    organizationId: string;
    externalProductId: string;
  } = useParams();

  const { defaultColumns } = useHistoryTableConfig(onRefresh, probeStatuses);

  const [columns, setColumns] = useState(defaultColumns);
  const { renderProbeData, formatProbeValue } = useProbeDataFormatter();

  const { isFetchingBatchStatuses } = useBatchApi({
    organizationId: organizationId,
  });

  const onClickRowHandler = (value: IBatchEvent) => {
    onClick && onClick(value.crn);
  };

  const onChangeTableColumns = useCallback(
    (id: string, label: string) => {
      localStorage.setItem(id, label);
      const splittedId = id.split('status');
      const columnId = splittedId.length === 2 ? splittedId[1] : 0;

      //@ts-ignore
      setColumns(prevState => {
        const currentTableColumns = prevState.map(item => {
          const dropDownValues =
            item.id === `property${columnId}`
              ? mapDropdowns(probeStatuses[label])
              : item.dropDownValues;
          return {
            ...item,
            label:
              item.id === id
                ? label
                : item.id === `property${columnId}`
                ? dropDownValues?.find(value => value.value === item.label)
                    ?.value ||
                  dropDownValues?.[0].value ||
                  '-'
                : item.label,
            dropDownValues: dropDownValues,
          };
        });
        return currentTableColumns;
      });
    },
    [probeStatuses],
  );

  const actions: () => IAction[] = useCallback(() => {
    const allActions = [
      {
        label: t(translations.pourEventPage.addManualData),
        onClick: (row: IBatchEvent) => {
          firebaseAnalytics.logPressEvent(
            Routes.SinglePourEvent,
            'Open Add Manual Data Modal',
          );

          onClick && onClick(row.crn, ModalType.MANUAL_DATA);
        },
      },
      {
        label: t(translations.pourEventPage.addComprStrength),
        onClick: (row: IBatchEvent) => {
          firebaseAnalytics.logPressEvent(
            Routes.SinglePourEvent,
            'Open Add Compressive Strength Modal',
          );

          onClick && onClick(row.crn, ModalType.COMPRESSIVE_STRENGTH);
        },
      },
    ];

    return allActions;
  }, [onClick, t]);

  const renderCell = (
    rowData: any,
    cellId: string,
    index: number,
    label: string,
  ) => {
    if (cellId === 'batchAge') {
      const cellContent = rowData.batchAge;

      return (
        <div className={classes.cellContainer}>
          {cellContent ? (
            <div
              className={
                index % divideByTwo === noReminderOfDivision
                  ? classes.ageContainerLight
                  : classes.ageContainerDark
              }
            >
              <CustomTypography
                variant="caption1"
                color={
                  index % divideByTwo === noReminderOfDivision
                    ? 'accentLight'
                    : 'white'
                }
              >{`${cellContent} min`}</CustomTypography>
            </div>
          ) : (
            '-'
          )}
        </div>
      );
    } else if (cellId === 'historyType') {
      return (
        <div>
          {rowData[cellId] === HistoryType.NOTIFICATION ? (
            <AlertIcon stroke={themeColors.systemRed} />
          ) : null}
        </div>
      );
    } else if (cellId === 'historyRecordName') {
      return (
        <div>
          <CustomTypography
            variant="bodySmall"
            color={rowData[cellId] === 'Voided' ? 'systemRed' : 'accentDark'}
          >
            {rowData[cellId]}
          </CustomTypography>
        </div>
      );
    } else if (cellId === 'totalOrdered') {
      return (
        <div>
          {`${formatProbeValue(rowData.cumulativeVolume)}/${renderProbeData(
            rowData[cellId],
          )}`}
        </div>
      );
    } else if (cellId.includes('property')) {
      const propIndx = cellId.charAt(cellId.length - oneElement);
      const currentStatus = columns.find(el => el.id === `status${propIndx}`);

      const latestMeasurement = rowData?.latestMeasurements?.find(
        (el: ILatestMeasurement) => el.eventStatusCode === currentStatus?.label,
      );

      const currentValue = latestMeasurement?.probeValues?.find(
        (value: ILatestMeasurementValue) => value.propertyName === label,
      )?.propertyValue;

      return (
        <div className={classes.wrapper}>
          <div
            className={
              latestMeasurement?.isManualMeasurement
                ? classes.valueContainerDark
                : ''
            }
          >
            {renderProbeData(currentValue)}
          </div>
        </div>
      );
    } else if (cellId === 'actions') {
      return (
        <div style={{ zIndex: 9999 }}>
          <ActionButton
            style={ActionButtonStyle.LIGHT}
            options={actions()}
            rowData={rowData}
          />
        </div>
      );
    } else {
      return (
        <div>
          {typeof rowData[cellId] === 'string'
            ? rowData[cellId]
            : renderProbeData(rowData[cellId])}
        </div>
      );
    }
  };

  return (
    <div>
      <SectionSpoiler
        withIcons={false}
        header={t(translations.pourEventPage.pourEventHistory)}
      >
        <ExactBanner />

        {!isFetchingBatchStatuses ? (
          <Table
            columns={columns}
            tableData={pourEventHistory}
            withCheckbox={false}
            rowsTotal={rowsTotal || defaultRows}
            renderCellWithIndexOfRow={renderCell}
            withColoredRows={true}
            onClickRow={onClickRowHandler}
            withPagination={true}
            baseURL={Routes.SinglePourEvent.replace(
              ':organizationId/:id/:pourEventId/:externalProductId/:name',
              `${organizationId}/${id}/${pourEventId}/${externalProductId}/${name}`,
            )}
            withPerPageCount={true}
            isLoading={isLoading}
            rowId={'crn'}
            emptyTableText={t(translations.pourEventPage.noHistory)}
            headRowsCount={2}
            searchParams={searchParams}
            onChangeHeadCell={onChangeTableColumns}
          />
        ) : (
          <CircularProgress withLabel />
        )}
      </SectionSpoiler>
    </div>
  );
};
