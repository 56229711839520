import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  header: {
    padding: '12px 20px',
    backgroundColor: themeColors.greyscale4,
  },
  internalContent: {
    height: 518,
    overflowY: 'auto',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: themeColors.greyscale4,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: themeColors.greyscale3,
    },
  },
  name: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '-9px 0px',
    whiteSpace: 'nowrap',
  },
  text: {
    paddingLeft: '8px',
  },
});
