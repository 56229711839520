import React, { useCallback, useEffect, useState } from 'react';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useStyles } from './NotificationsTabs.styles';
import { useHistory, useLocation } from 'react-router';
import { getNotificationsDefaultTab } from 'app/shared/utils';
import { useParams } from 'react-router-dom';

interface INotificationsTabsProps {
  categories: string[];
  currentTab?: string;
}

export const NotificationsTabs = ({
  categories,
  currentTab,
}: INotificationsTabsProps) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const notificationsTab = new URLSearchParams(location.search).get('tab');
  const { tab }: { tab: string } = useParams();
  const [activeTab, setActiveTab] = useState('');

  const changeTabHandler = useCallback(
    (tab: string) => {
      const searchParams = new URLSearchParams({
        ['tab']: tab,
      });

      history.push({
        pathname: location.pathname,
        search: `${searchParams}`,
      });
    },
    [history, location.pathname],
  );

  useEffect(() => {
    if (currentTab) {
      setActiveTab(currentTab);
    } else {
      setActiveTab(prevState => {
        return notificationsTab && notificationsTab?.length
          ? notificationsTab
          : !prevState
          ? getNotificationsDefaultTab(tab)
          : prevState;
      });
    }
  }, [currentTab, notificationsTab, tab]);

  return (
    <div className={classes.wrapper}>
      {categories.map(category => {
        return (
          <div
            className={activeTab === category ? classes.activeTab : classes.tab}
            key={category}
            onClick={() => changeTabHandler(category)}
          >
            <CustomTypography
              variant="buttonTabText"
              bold={activeTab === category}
              color={activeTab === category ? 'mbsBlue' : 'accentDark'}
            >
              {category}
            </CustomTypography>
          </div>
        );
      })}
    </div>
  );
};
