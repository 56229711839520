import { useStyles } from './BatchInfo.styles';
import { useMemo } from 'react';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Grid, GridVariant } from 'app/shared/components/generic-ui';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { IHistoryRecordExtended } from 'types';
import ExpandMoreIcon from '@material-ui/icons/ArrowForwardIos';
import { useBatchInfoValues } from '../hooks/useBatchInfoValues';
import { ReactComponent as AddIcon } from 'assets/add_compr_str.svg';
import { ReactComponent as HistoryIcon } from 'assets/history.svg';

export enum BatchInfoType {
  AGE,
  VALUE,
  BUTTONS,
}

interface IBatchInfoProps {
  batchHistory: IHistoryRecordExtended;
  infoSpoilerClickHandler: () => void;
  addCompressiveStrengthHandler: () => void;
  viewCompressiveStrengthHandler: () => void;
}

export const BatchInfo = ({
  batchHistory,
  infoSpoilerClickHandler,
  addCompressiveStrengthHandler,
  viewCompressiveStrengthHandler,
}: IBatchInfoProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { batchInfoValues } = useBatchInfoValues(batchHistory);

  const compressiveStrengthButtons = useMemo(() => {
    return (
      <div className={classes.icons}>
        <div className={classes.icon} onClick={addCompressiveStrengthHandler}>
          <AddIcon />
        </div>
        {batchHistory.compressiveStrengthMeasurements ? (
          <div
            className={classes.icon}
            onClick={viewCompressiveStrengthHandler}
          >
            <HistoryIcon />
          </div>
        ) : null}
      </div>
    );
  }, [
    addCompressiveStrengthHandler,
    batchHistory.compressiveStrengthMeasurements,
    classes.icon,
    classes.icons,
    viewCompressiveStrengthHandler,
  ]);

  return (
    <div className={classes.info}>
      {batchInfoValues.map(section => (
        <div
          style={{ display: 'flex', flexDirection: 'column' }}
          key={section.title}
        >
          <div className={classes.subheader}>
            <CustomTypography variant="header6" bold color="accentDark">
              {section.title}
            </CustomTypography>
          </div>

          <Grid container spacing={1}>
            {section.content.map(el => (
              <div key={el.label} className={classes.sectionItem}>
                <Grid item variant={GridVariant.MEDIUM}>
                  <CustomTypography variant="caption2" bold color="greyscale2">
                    {el.label}
                  </CustomTypography>
                </Grid>
                <Grid
                  item
                  variant={GridVariant.MEDIUM}
                  className={classes.valueContainer}
                >
                  <CustomTypography variant="bodySmall" color="accentDark">
                    {el.value}
                  </CustomTypography>

                  {el?.age ? (
                    <div className={classes.ageContainerLight}>
                      <CustomTypography variant="caption2" color="accentLight">
                        {el.age}
                      </CustomTypography>
                    </div>
                  ) : null}

                  {el.type === BatchInfoType.BUTTONS ? (
                    <div>{compressiveStrengthButtons}</div>
                  ) : null}
                </Grid>
              </div>
            ))}
          </Grid>
        </div>
      ))}

      <div className={classes.expandButton} onClick={infoSpoilerClickHandler}>
        <CustomTypography variant="caption1" color="mbsBlue">
          {t(translations.batchDetailsModal.showMore)}
        </CustomTypography>
        <ExpandMoreIcon className={classes.expandIcon} />
      </div>
    </div>
  );
};
