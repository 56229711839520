import React, { useCallback, useMemo } from 'react';
import {
  TableCell,
  TableRow,
  TableSortLabel,
  Checkbox,
  TableHead,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  CellAlignment,
  CellSize,
  IHeadCell,
  idCellWidth,
  ITableHeadProps,
  IPaginationUrlParams,
  TableHeaderType,
} from '../Table.types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useParams } from 'react-router-dom';
import { useStyles } from './TableHead.styles';
import { CustomTypography } from '../../Typography/Typography';
import { themeColors, themeShadows } from 'app/shared/theme/theme';

const oneLineHeader = 1;
const firstLineOfHeader = 0;
const firstElement = 0;
const onBackground = 'auto';
const inFront = 1500;

export const CustomTableHead: React.FC<ITableHeadProps> = ({
  orderBy,
  numSelected,
  rowCount,
  headCells,
  withCheckbox,
  hasActions,
  onSelectAllClick,
  headerType,
  sortHandler,
  onChangeHeadCell,
  headerButton,
  withPadding = true,
  withoutHeaderCheckbox,
}: ITableHeadProps) => {
  const classes = useStyles();
  const { orderType }: IPaginationUrlParams = useParams();
  const sortType = new URLSearchParams(location.search).get('sort');

  const defaultRows = 0;

  const headerRows = useMemo(() => {
    const rows: number[] = [oneLineHeader];

    headCells.map(cell => {
      if (cell?.rowNumber && cell.rowNumber > rows.length) {
        rows.push(cell.rowNumber);
      }
    });

    return rows;
  }, [headCells]);

  const handleSortLabelClick = useCallback(
    (id: string) => {
      sortHandler && sortHandler(id);
    },
    [sortHandler],
  );

  const headCellClassName = useMemo(() => {
    return headerType === TableHeaderType.LIGHT
      ? withoutHeaderCheckbox && withCheckbox
        ? classes.tableCellLightWithoutPadding
        : classes.tableCellLight
      : classes.tableCellDark;
  }, [
    classes.tableCellDark,
    classes.tableCellLight,
    classes.tableCellLightWithoutPadding,
    headerType,
    withCheckbox,
    withoutHeaderCheckbox,
  ]);

  return (
    <>
      {headerType !== 'noHeader' && (
        <TableHead className={classes.head}>
          {headerRows.map((row, index) => (
            <TableRow key={index}>
              {withCheckbox && (
                <TableCell
                  size={CellSize.SMALL}
                  className={headCellClassName}
                  classes={{
                    root: classes.cellVariant,
                    sizeSmall: classes.size,
                    stickyHeader:
                      headerType === TableHeaderType.LIGHT ||
                      (headerRows.length > oneLineHeader &&
                        index === firstLineOfHeader)
                        ? classes.headerVariant
                        : classes.header,
                  }}
                >
                  {!withoutHeaderCheckbox ? (
                    <Checkbox
                      indeterminate={
                        numSelected > defaultRows && numSelected < rowCount
                      }
                      classes={{
                        colorSecondary: classes.icon,
                        checked: classes.checkedIcon,
                      }}
                      checked={
                        rowCount > defaultRows && numSelected === rowCount
                      }
                      onChange={onSelectAllClick}
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  ) : null}
                </TableCell>
              )}
              {headCells.map((headCell: IHeadCell, key) => {
                return (
                  (headCell?.rowNumber === row || !headCell.rowNumber) && (
                    <TableCell
                      classes={{
                        root: !withPadding
                          ? classes.withoutPadding
                          : withCheckbox && !withoutHeaderCheckbox
                          ? classes.cell
                          : withoutHeaderCheckbox
                          ? classes.tableCellLightWithoutPadding
                          : classes.cellWithoutCheckbox,
                        alignJustify: classes.justify,
                        stickyHeader:
                          headerType === TableHeaderType.LIGHT ||
                          (headerRows.length > oneLineHeader &&
                            index === firstLineOfHeader)
                            ? classes.headerVariant
                            : headerType === TableHeaderType.ACTIVE
                            ? classes.headerActive
                            : classes.header,
                      }}
                      className={headCellClassName}
                      align={headCell.alignment}
                      padding={'none'}
                      sortDirection={
                        orderBy === headCell.id ? orderType : false
                      }
                      size={headCell.size}
                      style={{
                        boxShadow: headCell.sticky
                          ? themeShadows.shadowMedium
                          : '',
                        position: headCell.sticky ? 'sticky' : 'relative',
                        left: 0,
                        zIndex: headCell.sticky ? inFront : onBackground,
                        padding:
                          headerType === TableHeaderType.LIGHT
                            ? '16px 16px 0px 16px'
                            : '',
                        borderBottom:
                          headerType === TableHeaderType.LIGHT ? 'none' : '',

                        fontWeight:
                          headerType === TableHeaderType.LIGHT
                            ? 'normal'
                            : 'bold',
                        width:
                          !headCell.sortable && headCell.dropDownValues
                            ? '170px'
                            : headCell.maxWidth
                            ? '100%'
                            : 'auto',
                      }}
                      colSpan={headCell.colSpan}
                      rowSpan={headCell.rowSpan}
                      key={key}
                    >
                      {headCell.borderLeft && (
                        <div className={classes.borderContainer}>
                          <div className={classes.borderLeft} />
                        </div>
                      )}
                      {headCell.sortable && (
                        <TableSortLabel
                          classes={{
                            root: classes.label,
                            icon: classes.checkedIcon,
                          }}
                          active={
                            headCell.sortBy
                              ? orderBy === headCell.sortBy
                              : orderBy === headCell.id
                          }
                          IconComponent={ExpandMoreIcon}
                          direction={
                            orderBy && orderType
                              ? orderType
                              : sortType === 'asc' || sortType === 'desc'
                              ? sortType
                              : undefined
                          }
                          onClick={() => handleSortLabelClick(headCell.id)}
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          <CustomTypography
                            variant="subtitle2"
                            color={
                              headerType === TableHeaderType.DARK
                                ? 'greyscale1'
                                : headerType === TableHeaderType.ACTIVE
                                ? 'white'
                                : 'greyscale2'
                            }
                            bold
                          >
                            {headerType === TableHeaderType.DARK ||
                            headerType === TableHeaderType.ACTIVE
                              ? headCell.label.toUpperCase()
                              : headCell.label}
                          </CustomTypography>
                        </TableSortLabel>
                      )}
                      {!headCell.sortable && !headCell.dropDownValues && (
                        <>
                          {headCell?.buttons ? (
                            <div
                              className={classes.buttonsCell}
                              style={{
                                justifyContent:
                                  headCell.alignment === CellAlignment.RIGHT
                                    ? 'flex-end'
                                    : headCell.alignment ===
                                      CellAlignment.CENTER
                                    ? 'center'
                                    : 'flex-start',
                              }}
                            >
                              {headCell?.buttons?.map((button, index) => (
                                <div
                                  key={index}
                                  style={{
                                    borderLeft:
                                      index !== firstElement
                                        ? `1px solid ${themeColors.greyscale3}`
                                        : '',
                                  }}
                                  onClick={button.onClick}
                                >
                                  {button.button}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <CustomTypography
                              variant="subtitle2"
                              color={
                                headerType === TableHeaderType.DARK
                                  ? 'greyscale1'
                                  : headerType === TableHeaderType.ACTIVE
                                  ? 'white'
                                  : 'greyscale2'
                              }
                              bold={
                                headerType === TableHeaderType.DARK ||
                                headerType === TableHeaderType.ACTIVE
                              }
                            >
                              {headerType === TableHeaderType.DARK ||
                              headerType === TableHeaderType.ACTIVE
                                ? headCell.label.toUpperCase()
                                : headCell.label}
                            </CustomTypography>
                          )}
                        </>
                      )}
                      {!headCell.sortable && headCell.dropDownValues && (
                        <Select
                          displayEmpty
                          id="demo-simple-select-outlined"
                          value={headCell.label}
                          variant="outlined"
                          onChange={event => {
                            onChangeHeadCell &&
                              onChangeHeadCell(
                                headCell.id,
                                event.target.value as string,
                              );
                          }}
                          name="dropdown"
                          classes={{
                            outlined: classes.select,
                            root: classes.root,
                          }}
                          inputProps={{
                            root: classes.root,
                          }}
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                        >
                          {headCell.dropDownValues.map((item, index) => (
                            <MenuItem
                              value={
                                typeof item === 'string' ? item : item.value
                              }
                              key={index}
                            >
                              <CustomTypography
                                variant="subtitle2"
                                color="accentDark"
                              >
                                {typeof item === 'string' ? item : item.label}
                              </CustomTypography>
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </TableCell>
                  )
                );
              })}
              {hasActions && (
                <TableCell
                  classes={{
                    root: classes.cell,
                    stickyHeader:
                      headerType === TableHeaderType.LIGHT ||
                      (headerRows.length > oneLineHeader &&
                        index === firstLineOfHeader)
                        ? classes.headerVariant
                        : classes.header,
                  }}
                  style={{ width: idCellWidth }}
                  align={CellAlignment.RIGHT}
                >
                  {headerButton ? headerButton : ''}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableHead>
      )}
    </>
  );
};
